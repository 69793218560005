import {extend} from "vee-validate";

/**
 * VeeValidateのカスタムルール
 */
// 半角記号
extend('half_width_symbol', {
    message: '{_field_}に使用できない文字が入力されています',
    validate(value) {
        var rule = new RegExp(/[!"#$%&'()\*\+,:;<=>?@\[\\\]^_`{|}~]/g);
        return !rule.test(value);
    }
});
extend('alpha_num_symbol', {
    message: 'パスワードに使用できない文字が入力されています',
    validate(value) {
        const rule = new RegExp(/^[ -\~]+$/);
        return rule.test(value);
    }
});
extend('alpha_num_org', {
    message: '半角英数字で入力してください',
    validate(value) {
        const rule = new RegExp(/[^A-Za-z0-9]+/);
        return !rule.test(value);
    }
});
// 全角カタカナ
extend('katakana', {
    params: ['str'],
    message: 'カタカナで入力してください',
    validate(value) {
        return value.match(/^[ァ-ヶー　]+$/);
    }
});
// 第二水準以下文字
extend('jis_level2_char', value => {
    return axios.post('/api/buy/validateKanjiLevel', {
        name: value
    }).then(response => {
        return response.data.result ? true : "ご利用できない漢字が含まれております。";
    })
});
// メールアドレスの有効性（登録済みかどうか）
extend('email_format', {
    message: 'すでに会員登録済みのメールアドレスです。',
    validate(value) {
        return axios.post('/api/account/validateEmail', {
            email: value
        }).then(response => {
            return response.status === 200
        }).catch(error => {
            return error.response.status === 409 ? this.message : '時間を置いて再度お試しください。';
        })
    }
});
// メールアドレスの形式チェック
extend('email_character', {
    message: 'メールアドレスの形式が誤っている可能性があります。',
    validate(email) {

        // ローカルパートに連続した「.」(ドット)と@直前に「.」(ドット)は入力可能とする（先頭の「.」(ドット)は不可）
        const regex = /^(?=(?:\([^)]*\))*[^()\n]+[^@\n]*@)(?!(?:\([^)]*\))*\.)(?:(?:[\w!#$%&'*+/=?^`{|}~.-]|\([\w!#$%&'*+/=?^`{|}~.-]*\))+|"(?:[\w!#$%&'*+/=?^`{|}~.-]|\([\w!#$%&'*+/=?^`{|}~.-]*\))*")@(?:\([A-Za-z0-9-]*\))*(?:[A-Za-z0-9]+(?:(?:\([A-Za-z0-9-]*\))*-(?:\([A-Za-z0-9-]*\))*[A-Za-z0-9]+)*(?:\([A-Za-z0-9-]*\))*)(?:\.(?:\([A-Za-z0-9-]*\))*(?:[A-Za-z0-9]+(?:(?:\([A-Za-z0-9-]*\))*-(?:\([A-Za-z0-9-]*\))*[A-Za-z0-9]+)*(?:\([A-Za-z0-9-]*\))*))+$/;
        const match = email.match(regex);
        if(!match) return false;

        const topLevelDomainRegex = /\.[A-Za-z-]+$/;
        const topLevelDomainMatch = topLevelDomainRegex.exec(email);

        // トップレベルドメインが存在しないまたは数値がある場合エラーとする
        if (!(topLevelDomainMatch && topLevelDomainMatch[0])) {
            return this.message;
        }

        const domainPart = email.split('@')[1];
        const parenthesesPattern = /[()]/;

        if(!domainPart) {
            return this.message;
        }

        const dividedValues = domainPart.split('.');
        const topLevelDomain = dividedValues[dividedValues.length - 1];
        // トップレベルドメインが2文字以下の場合エラーとする
        if (topLevelDomain.length < 2) {
            return this.message;
        }

        // ドメインパートに括弧がある場合エラーとする
        if(parenthesesPattern.test(domainPart)) {
            return this.message;
        }

        return true;
    }
});
extend('address',
    {
        message: '住所を入力してください',
        params: ['postcode', 'prefecture', 'localAddress2'],
        validate(localAddress1, {postcode, prefecture, localAddress2}) {
            const pattern = /^\d*$/; // 0以上の数値 0始まりもOK
            if (!pattern.test(postcode)) {
                return '郵便番号を再度入力してください。';
            }
            return axios.post('/api/buy/validateAddress', {
                postcode: postcode,
                state: prefecture,
                address1: localAddress1,
                address2: localAddress2

            }).then(() => {
                return true;

            }).catch(e => {
                if (e.response.status === 422) {
                    return e.response.data.message;
                }
                return '時間を置いて再度お試しください。';
            });
        }
    },
    {hasTarget: true}
);
extend('charibon_address', {
    message: '住所を入力してください',
    params: ['postcode', 'prefecture', 'localAddress2', 'siteCode', 'supportGroupId'],
    validate(localAddress1, {postcode, prefecture, localAddress2, siteCode, supportGroupId}) {
        const pattern = /^\d*$/; // 0以上の数値 0始まりもOK
        if (!pattern.test(postcode)) {
            return '郵便番号を再度入力してください。';
        }
        return axios.post(`/api/buy/validateCharibonAddress`, {
            site_code: siteCode,
            supportGroupId: supportGroupId,
            postcode: postcode,
            state: prefecture,
            address1: localAddress1,
            address2: localAddress2,
        }).then(response => {
            if (!response) {
                return '住所を入力してください';
            }            
            if (!response.data.result) {
                return "都道府県・市区町村番地・建物名合わせて" + response.data.availableMaxLength + "文字まで入力可能です。";
            }
            return true;
        }).catch(e => {
            console.error("validateCharibonAddress Error: " + e);
            return false;
        });
    },
});
extend('address_length', {
    message: "都道府県・市区町村番地・建物名合わせて80文字まで入力可能です。",
    params: ['prefecture', 'localAddress2'],
    validate(localAddress1, {prefecture, localAddress2}) {
        const totalAddress = prefecture + localAddress1 + localAddress2;
        return !(totalAddress.length > 80);
    },
});

extend('age_18_or_over',
    {
        message: '18歳からご利用可能です',
        validate(_, [birthdateYear, birthdateMonth, birthdateDay]) {
            if (!birthdateYear || !birthdateMonth || !birthdateDay) {
                return true;
            }

            let today = new Date();
            let birthdate = new Date(birthdateYear, birthdateMonth - 1, birthdateDay);
            let birthdateOfThisYear = new Date(today.getFullYear(), birthdateMonth - 1, birthdateDay);

            let age = today.getFullYear() - birthdate.getFullYear();
            if (today < birthdateOfThisYear) {
                age--;
            }

            return 18 <= age;
        }
    },
    {hasTarget: true}
);
extend('hiragana', {
    params: ['str'],
    message: 'ひらがなで入力してください',
    validate(value) {
        return value.match(/^[あ-ん゛゜ぁ-ぉゃ-ょー「」、]+/);
    }
});
extend('buyback_code',
    {
        message: 'ご利用できない{_field_}です。',
        validate(value) {
            return axios.get('/api/buy/validateBuybackCode', {
                params: {
                    'buyback_code': value,
                }

            }).then(() => {
                return true;

            }).catch(e => {
                if (e.response.status === 422) {
                    return false;
                }
                return '時間を置いて再度お試しください。';
            });
        }
    },
    {hasTarget: true}
);
extend('job_code', {
    message: 'ご職業は必須項目です',
    validate(value) {
        if (!!value['code']) {
            return true;
        }
    }
});
extend('jp_bank_branch_code',
    {
        message: 'ご利用できない{_field_}です。',
        validate(value) {
            // FIXME: site_codeをVaboo固定値を渡している Vue.prototype.$siteCodeの外のため
            return axios.post('/api/bank/validateJpBankSign', {
                yuucyo_kigou: value,
                site_code: VueConfig.siteCode.vaboo
            }).then(response => {
                return response.data
            }).catch(e => {
                if (e.response.status === 422) {
                    return false;
                }
                return '時間を置いて再度お試しください。';
            });
        }
    },
    {hasTarget: true}
);